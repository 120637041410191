import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import 'jspdf-autotable'
import { format } from "date-fns"
    
const COLUMNS_HEADER = ['N°', 'SG', 'RUT', 'NOMBRE', 'SOURCE', 'COURIERS']

export const generateSelectedSgsPdf = (sgs: any[], action: string) => {
  const doc = new jsPDF()
  doc.text('Manifiesto', 14, 20)
  autoTable(doc, {
    startY: 30,
    theme: 'grid',
    head: [COLUMNS_HEADER],
    body: sgs.map((sg, i) => {
      return [
        `${i + 1}`,
        sg?.id,
        sg?.target?.customer?.id,
        sg?.target?.customer?.name,
        sg?.source?.id,
        sg?.custom?.courier
      ]
    })
  })
  
  autoTable(doc, {
    margin: { top: 20 },
    theme: 'grid',
    head: [['RESUMEN', '']],
    body: [
      ['Total de SG', sgs.length],
      ['Fecha y hora', new Date().toLocaleString()],
    ]
  })
  // doc.output('dataurlnewwindow', {filename: `SG_${action}_${new Date().getTime()}.pdf`})
  //action === "shipped" && doc.save(`SG_${action}_${new Date().getTime()}.pdf`)
  doc.save(`SG_${action}_${new Date().getTime()}.pdf`)
  // doc.save(`SG_${action}_${new Date().getTime()}.pdf`)
}

export const downloadManifest = (item: any) => {
  const doc = new jsPDF()
  doc.text('Manifiesto', 14, 20)
  autoTable(doc, {
    startY: 30,
    theme: 'grid',
    head: [COLUMNS_HEADER],
    body: item?.sgs?.map((sg:any, i:any) => {
      return [
        `${i + 1}`,
        sg?.sg,
        sg?.rut,
        sg?.name,
        sg?.source,
        sg?.courier
      ]
    })
  })
  
  autoTable(doc, {
    margin: { top: 20 },
    theme: 'grid',
    head: [['RESUMEN', '']],
    body: [
      ['Total de SG', item?.sgs?.length],
      ['Fecha y hora', format(new Date(item?.date), "yyyy-MM-dd HH:mm:ss")],
    ]
  })
  doc.save(`SG_${item?._id}.pdf`)
}