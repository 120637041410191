import { useState, useEffect, useContext } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import { useHistory } from "react-router-dom"
import { OderGroupingType } from "types/OrderType"
import Logger from "classes/Logger"
import { executeShipingGroupAction } from "services/ShippingGroupService"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import useConditionalsGroupOrder from "./UseConditionalsGroupOrder"
import { generateSelectedSgsPdf } from "helpers/pdfGenerationHelper"
import useCancelToken from "hooks/UseCancelToken"
import { useEnv } from "hooks"
import { AuthContext } from "context/context/AuthContext"
import { format } from "date-fns"
import { manifestGenerate } from "services/ListenerServices"

const useChekedOrderList = (ordersGroup: OderGroupingType) => {
  const [ordersSGChecked, setOrdersSGChecked] = useState<Array<string>>([])
  const [ordersSGCheckedToPrint, setOrdersSGCheckedToPrint] = useState<Array<any>>([])
  const [selectAll, setSelectAll] = useState(false)
  const [disabledUpdateSG, setDisabledUpdateSG] = useState(true)
  const [loadingUpdateSG, setLoadingUpdateSG] = useState(false)
  const { newCancelToken } = useCancelToken()
  const [_client] = useEnv().multiclient.get_user_env()
  const { notification, errorHander } = useContext(GlobalContext)
  const history = useHistory()

  const { canSelectMultiple, statusGroup, typeStatus } = useConditionalsGroupOrder()

  const {
    state: { user },
  } = useContext(AuthContext)

  useEffect(() => {
    const anySelected = ordersSGChecked.length === 0
    setDisabledUpdateSG(anySelected)
  }, [ordersSGChecked])

  const checkAllOrders = (checked: boolean) => {
    setSelectAll(!selectAll)

    if (!checked) {
      setOrdersSGChecked([])
      setOrdersSGCheckedToPrint([])
      return
    }
    if (ordersGroup) {
      let ordersId: Array<string> = []
      let letOrdersToPrint: Array<any> = []
      const orders = Object.keys(ordersGroup)

      if (statusGroup?.predicate) {
        const { predicate } = statusGroup
        ordersId = orders.flatMap((key) => ordersGroup[key].filter(predicate).map(({ id }) => id))
        letOrdersToPrint = orders.flatMap((key) =>
          ordersGroup[key].filter(predicate).map(({id, orderId, source, target, custom}) =>
            ({id, orderId, source, target, custom})))
      } else {
        ordersId = orders.flatMap((key) => ordersGroup[key].map(({ id }) => id))
        letOrdersToPrint = orders.flatMap((key) => ordersGroup[key].map(({
          id, orderId, source, target, custom
        }) => ({id, orderId, source, target, custom})))
      }
      setOrdersSGChecked(ordersId)
      setOrdersSGCheckedToPrint(letOrdersToPrint)
    }
  }

  const saveManifest = async (resultOrdersPrint: any) => {
    try {
      let body: any = {
        date: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        user: user?.id,
        source: user?.currentSources && user?.currentSources?.length > 1  ? user?.role : user?.currentSources[0]
      }
      body.sgs = resultOrdersPrint.map((item:any) => {
        return {
          sg: item?.id,
          rut: item?.target?.customer?.id,
          name: item?.target?.customer?.name,
          source: item?.source?.id,
          courier: item?.custom?.courier
        }
      })

      const {
        data: { code, message },
      } = await manifestGenerate(body, newCancelToken())

      if (code === TransactionCodeEnum.ok && message) {
        setLoadingUpdateSG(false)
        generateSelectedSgsPdf(resultOrdersPrint, "shipped")

        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "se genero el manifiesto correctamente",
            title: "Actualización SG’s",
            type: "success",
          },
        })
        
        setTimeout(() => history.push("/dashboard"), 2000)
      }else{
        setLoadingUpdateSG(false)
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Ha ocurrido un error inesperado en la aplicación. por favor intente nuevamente",
            title: "ERROR",
            type: "danger",
            delay: 8000,
          },
        })

        setTimeout(() => history.push("/dashboard"), 2000)
      }
    } catch (error: any) {
      setLoadingUpdateSG(false)
      Logger.error(error)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  const updateShippingGroupAction = async () => {
    if (!statusGroup) return

    try {
      setLoadingUpdateSG(true)

      if (_client === "wom" && statusGroup.action === "shipped") {
        saveManifest(ordersSGCheckedToPrint)
      } else {
        const executeOrders = ordersSGChecked.map((idSG) =>
          executeShipingGroupAction({
            shippingGroupId: idSG,
            action: statusGroup.action,
          }),
        )
        
        const [resultUpdateOrders] = await Promise.all(executeOrders)

        setLoadingUpdateSG(false)

        if (resultUpdateOrders.data.code === TransactionCodeEnum.ok) {
          notification?.dispatch({
            type: "ADD_NOTIFICATION",
            payload: {
              message: "se actualizo correctamente los SG’s",
              title: "Actualización SG’s",
              type: "success",
            },
          })

          setTimeout(() => history.push("/dashboard"), 2000)
        }else {
          notification?.dispatch({
            type: "ADD_NOTIFICATION",
            payload: {
              message: "Ha ocurrido un error inesperado en la aplicación. por favor intente nuevamente",
              title: "ERROR",
              type: "danger",
              delay: 8000,
            },
          })

          setTimeout(() => history.push("/dashboard"), 2000)
        }
      }
    } catch (error: any) {
      setLoadingUpdateSG(false)
      Logger.error(error)
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "Ha ocurrido un error inesperado en la aplicación. por favor intente nuevamente",
          title: "ERROR",
          type: "danger",
          delay: 8000,
        },
      })
    }
  }

  return {
    canSelectMultiple,
    ordersSGChecked,
    selectAll,
    setOrdersSGChecked,
    checkAllOrders,
    statusSG: typeStatus,
    disabledUpdateSG,
    loadingUpdateSG,
    updateShippingGroupAction,
    conditionChecked: statusGroup?.predicate,
    labelGroupAction: statusGroup?.labelAction,
    ordersSGCheckedToPrint,
    setOrdersSGCheckedToPrint
  }
}

export default useChekedOrderList
